.uh-uh-shake {
  animation: errorShake 0.2s linear;
}

.grow-shrink {
  animation: grow-shrink 1s ease-in-out infinite;
}

.grow-shrink-2 {
  animation: grow-shrink-2 1s ease-in-out infinite;
  animation-delay: 0.2s;
}

@keyframes errorShake {
  0% {
    transform: translate(-1rem);
  }

  50% {
    transform: translate(1rem);
  }

  100% {
    transform: translate(0px);
  }
}

@keyframes grow-shrink {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes grow-shrink-2 {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(0.8);
  }
}